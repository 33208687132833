body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Form grid */
#main-content .simple-form .MuiCardContent-root {
  display: grid;
  grid-template-columns: repeat(auto-fit, 280px);
}

.MuiChip-root.MuiChip-sizeSmall {
  border-radius: 5px;
  height: 18px;
}
.MuiChip-sizeSmall .MuiChip-labelSmall {
  padding: 4px;
  font-size: 0.75rem;
  height: 15px;
}
.MuiSnackbarContent-message {
  color: white;
}

.RaLoading-container-1 {
  height: 100vh !important;
  margin-top: -3em;
}

.driverKuerzel > span {
  transition: 0.5s all;
}

.driverKuerzel > span:hover {
  background-color: black;
  color: white;
  border-color: black;
}

/* Adjust <SearchInput /> height to other filter inputs */
.MuiToolbar-root .MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense {
  padding-top: 18px;
}
